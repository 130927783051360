/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, canonical, lang, meta, image, title }) {
  const { site } = useStaticQuery(
    graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
    `
    )
    
    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = title || site.siteMetadata.title;
    const metaImage = image || "";
    const metaCanonical = canonical || "";

    useEffect( ()=> {
      let helmetTags = document.querySelectorAll('[data-react-helmet="true"]');
      console.log("Helmet Tag length", helmetTags.length, "Helmet tags", helmetTags);
      if(helmetTags.length > 0 ) {
        let tag;
          for(var i = 0; i < helmetTags.length; i++) {
            tag = helmetTags[i];
            if(tag.hasAttribute("data-react-helmet")){
              tag.removeAttribute("data-react-helmet");
              tag.setAttribute("data-react-helmet","true");
            }
          }
      }
    }, [])
    
    return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      //titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: defaultTitle ,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaCanonical,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author || ``,
        },
        {
          name: `twitter:title`,
          content: defaultTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}

      link={
        canonical
          ? [{ rel: 'canonical', key: canonical, href: canonical }]
          : []
      }
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
