/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { withPrefix } from "gatsby"
import { useEffect } from 'react'
import "./layout.css"
import "../assets/sass/theme.scss"
import "../assets/css/theme.css"
import "../assets/css/designer-overrides.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
    allPrismicSocialInformation {
      nodes {
        data {
          social_links {
            social_icon {
              alt
              thumbnails {
                inverted {
                  url
                }
              }
            }
            social_link {
              url
            }
          }
        }
      }
    }
  }
  `)

  const social = data.allPrismicSocialInformation.nodes[0].data.social_links;

  console.log("LAYOUT SOCIAL", social);
  return (
    <>
        {/* Includes script in local environment */}
        {
          (typeof window != 'undefined') && window.location.port === 8000 &&
            <Helmet>
              <script src={withPrefix('/javascript/app.js')} type="text/javascript" />
            </Helmet>
        }
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
      <div id="offcanvas-wrapper">
        { /*  Header  */}
        <header id="header">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-header">
                <a href="/" className="navbar-brand">
                  <img src="/images/logo-l5.svg" alt="Level Five Solutions Logo" width="192" />
                </a>
              </div>
              <div className="navbar-right">
                <button id="btn-offcanvas-toggle" type="button" className="btn btn-default navbar-btn"><span>More</span>&nbsp;&nbsp;&nbsp;<span className="icn-menu">[Toggle Menu]</span></button>
              </div>
            </div>
          </nav>            
        </header>

        <main>{children}</main>
    
        <p className="copyright-alignment txt-gray-50 txt-18 text-center">&copy;{new Date().getFullYear()} Level Five Solutions, Inc. All rights reserved.</p>

        <footer id="footer">
          <div className="container">
              <div className="row">
                  <div className="col-md-7 footer-left"><strong>Got a project in mind?</strong></div>
                  <div className="col-md-5 footer-right"><a href="/contact-us">LET'S TALK &rarr;</a></div>
              </div>
          </div>
        </footer>

        { (typeof window != 'undefined') &&

          <aside id="offcanvas-menu" className="offcanvas">
            <div className="sidebar-header bar">
                <div className="logo left"><img src="/images/logo-l5-inverse.svg" width="137" height="40" alt="Level Five Solutions" /></div>
                <a href="#" className="offcanvas-close right"><span className="icn-x">[CLOSE]</span></a>
            </div> {/*  /bar  */}

            <ul className="nav-list">
                <li className={`${window.location.pathname === '/' && 'active'}`}>
                    <a href="/">Home</a>
                </li>
                <li className={`${window.location.pathname === '/capabilities/' && 'active'}`}>
                    <a href="/capabilities">Capabilities</a>
                </li>
                <li className={`${window.location.pathname === '/work/' && 'active'}`}>
                    <a href="/work">Work</a>
                </li>
                <li className={`${window.location.pathname === '/contact-us/' && 'active'}`}>
                    <a href="/contact-us">Contact Us</a>
                </li>
            </ul>
            <div className="map-pin bar">
              <a href="/contact-us?tab=other">
                <span className="pin-label left txt-white">Driving to the<br /><strong className="txt-white">KC OFFICE?</strong></span>
                <span className="pin-icon icn-pin-kc-inv right txt-white">[Map Pin]</span>
              </a>
            </div>

            <div className="social-media">
            {
              social.map( (d, i ) => {
                console.log("d", d);
                return(
                  <a key={ i } href={ d.social_link.url } target="_blank">
                    <img width="30" src={ d.social_icon.thumbnails.inverted.url } alt={ d.social_icon.alt }/>&nbsp;
                  </a>
                )
              })
            }
            </div>
            <div className="copyright">&copy; {new Date().getFullYear()} Level Five Solutions, Inc.</div>
          </aside>

        }

      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
